import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { aboutUsSection } from '../staticdata/tabsData'
import FooterSectionComponent from './FooterSectionComponent'
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'
import bgImage from '../images/slicing/home/footerbg.png'
const data = [
    {
        activeKey: 'productengineering', label: 'Digital Product Engineering', clickable: true, key: 'productengineering', submenu: [
            { activeKey: 'productdevelopment', clickable: true, label: 'Product Development ', key: 'productdevelopment' },
            { activeKey: 'lowcodeintelligent', clickable: false, label: 'Low Code Intelligent Automation', key: 'lowcodeintelligent' },
            { activeKey: 'sustenance', clickable: false, label: 'Sustenance & Support', key: 'sustenance' },
            { activeKey: 'innovationacceleration', clickable: false, label: 'Innovation Acceleration', key: 'innovationacceleration' }]
    },

    {
        activeKey: 'legacymodernization', label: 'Legacy Modernization', clickable: true, key: 'legacymodernization', submenu: [

            { activeKey: 'cloudnative', clickable: true, label: 'Cloud Native', key: 'cloudnative' },
            { activeKey: 'managedservices', clickable: false, label: 'Managed Services', key: 'managedservices' },
            { activeKey: 'applicationarchitecture', clickable: false, label: 'Application Architecture Services', key: 'applicationarchitecture' }]
    },

    {
        activeKey: 'testingservice', clickable: true, label: 'Test Engineering', key: 'testingservice', submenu: [

            { activeKey: 'testautomation', clickable: false, label: 'Test Automation', key: 'testautomation' },
            { activeKey: 'performancetestengineering', clickable: true, label: 'Performance Test Engineering', key: 'performancetestengineering' },
            { activeKey: 'mobiletesting', clickable: true, label: 'Mobile Application Testing', key: 'mobiletesting' },
            { activeKey: 'salesforcetesting', clickable: true, label: 'Salesforce Testing', key: 'salesforcetesting' },
            { activeKey: 'cloudtesting', clickable: false, label: 'Cloud Infrastructure Testing', key: 'cloudtesting' },
            { activeKey: 'automationconsulting', clickable: false, label: 'Automation Framework Consulting', key: 'automationconsulting' },
            { activeKey: 'testdatamanagement', clickable: false, label: 'Test Data Management', key: 'testdatamanagement' }]
    }
]
const SecondaryFooter = () => {
    return (
        <Container fluid className="secondary-footer px-0 " style={{ backgroundImage: `url(${bgImage})` }}>
            <Container>
                <Row noGutters>
                    <Col>
                        <h3>{aboutUsSection.heading}</h3>
                        <hr />
                        <p>{aboutUsSection.description} </p>
                        <div>
                            <a className="px-1" href="https://www.facebook.com/GTENTechnologies/" target="_blank" rel="noreferrer" aria-label="Facebook" > <FaFacebook color="white" /></a>
                            <a className="px-1" href="https://twitter.com/gtentechnology" target="_blank" rel="noreferrer" aria-label="Twitter"><FaTwitter color="white" /></a>
                            <a className="px-1" href="https://www.linkedin.com/company/gtentechnologies" target="_blank" rel="noreferrer" aria-label="Linked In"><FaLinkedin color="white" /></a>
                            <a className="px-1" href="https://www.instagram.com/hrgten/" target="_blank" rel="noreferrer" aria-label="Linked In"><FaInstagram color="white" /></a>
                        </div>
                    </Col>
                    <FooterSectionComponent sectionHeading="Services" tabs={data[0]} />
                    <FooterSectionComponent sectionHeading="" tabs={data[1]} />
                    <FooterSectionComponent sectionHeading="" tabs={data[2]} />
                </Row>
            </Container>
        </Container>
    )
}
export default SecondaryFooter